import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';
import { FormGroup, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { getCompanyName } from '@/shared/intl';


const TextField = (props) => {
    const { field, error, onValue, className = '' } = props;
    const option = 'You can send me text messages\n';
    const labelOptIn = `By providing your number and checking this box, you agree to receive periodic text messages from ${getCompanyName()}. Standard text rates apply. Reply STOP at any time to unsubscribe.`;
    const optInFieldName = field.name.replace('value', 'smsOptin');

    field.required = field.sms_opt_in || field.required;

    return (
        <FormFieldContainer
            formControl={
                <div>
                    <FormGroup>
                        <Row>
                            <input
                                type='tel'
                                className={'form-field'}
                                name={field.name}
                                placeholder={field.placeholder_text}
                                autoComplete="off"
                                onChange={(e) => onValue(e.target.value)}/>
                        </Row>
                        { field.required_opt_in && <Row className='opt-in-container'>
                            <div className='input-field checkbox-field field-container field-container-dynamic' data-hidden={!field.required_opt_in} data-type='checkbox' data-name={optInFieldName}>
                                <div style={{ position: 'relative' }} className="input-container">
                                    <div>
                                        <label key='option-1' className="checkbox opt-in-checkbox">
                                            <div className="checkbox-container">
                                                <input type="checkbox" checked={field.required_opt_in && field.sms_opt_in} name={optInFieldName} value={option}/>{' '}
                                                <div className="box">
                                                    <FontAwesomeIcon icon={faCheck} className="box-icon"/>
                                                </div>
                                            </div>
                                            <div className="label-container" dangerouslySetInnerHTML={{ __html: option }} />
                                        </label>
                                        <div className='opt-in-label-container'>
                                            <label className='opt-in-label'> {labelOptIn} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row> }
                    </FormGroup>
                </div>
            }
            fieldType="tel"
            error={error}
            field={field}
            className={className}
        />
    );

};

export default TextField;
