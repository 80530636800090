/**
 * Requests that the outer frame initialize the process for creating a new custom field.  It is expected that the
 * outer frame will use the MessagePort provided to send back a response or error.
 *
 * In the case of a valid response, the response data should be an unlayer form field.
 *
 * @return {Promise<UnlayerFormField>}
 */
import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

export const requestCreateFormField = async () => executeFrameRequest('createFormField');

export const requestCreatePhoneNumber = async () => executeFrameRequest('createPhoneNumber');

export const requestCreateVirtualField = async () => executeFrameRequest('createVirtualField');
