import React from 'react';
import { Input, Label } from 'reactstrap';
import { getKmnAccount, intl } from '@/shared/intl';
import style from '../../../styles/FormFieldModal.scss';



const MAX_NAME_LENGTH = 64;

class TextField extends React.Component {
    constructor(props) {
        super(props);

        this.state = { hasFocus: false };
    }

    setFocus(hasFocus) {
        this.setState({ hasFocus });
    }

    render() {
        const { field, errors, handleFieldChange, urlParamsEnabled } = this.props;
        const { label, type, placeholder_text, url_param_text, show_label, required, required_opt_in, hidden, name } = field;

        return (
            <div>
                <div className={style.columnStyle}>
                    <Label className={style.labelStyle}>Field Label</Label>
                    <Input
                        className={style.inputField}
                        style={(label?.length >= 64 && this.state.hasFocus) ? { border: '1px solid red' } : {}}
                        invalid={!!errors['label']}
                        type="text"
                        name="label"
                        value={label}
                        maxLength={MAX_NAME_LENGTH}
                        onFocus={this.setFocus.bind(this, true)}
                        onBlur={this.setFocus.bind(this, false)}
                        onChange={(e) => handleFieldChange('label', e.target.value)}
                    />
                    {(
                        <div style={{ marginTop: '5px', 'font-size': '13px' }} className={style.labelStyle}>
                            <div style={{ color: 'red' }}>
                                {
                                    (label?.length >= 64 && this.state.hasFocus) ? intl.get('tool.form.fields.name.error.maxLength', { defaultMessage: 'Can\'t exceed 64 characters' }) : ''
                                }
                            </div>
                            {
                                (label?.length >= 64 && this.state.hasFocus) ? <div style={{ color: 'red' }}>{label?.length}/{MAX_NAME_LENGTH}</div> : <div>{label?.length}/{MAX_NAME_LENGTH}</div>
                            }
                        </div>
                    )}
                </div>


                <div className={style.columnStyle}>
                    <Label className={style.labelStyle}>Placeholder Text</Label>
                    <Input
                        className={style.inputField}
                        type="text"
                        name="label"
                        value={placeholder_text}
                        onChange={(e) => handleFieldChange('placeholder_text', e.target.value)} />
                </div>

                <div className={style.rowStyle}>
                    <div className={style.inputFieldContainer}>
                        <Input
                            className={style.inputField}
                            type="checkbox"
                            checked={required && !hidden}
                            disabled={hidden === true}
                            onChange={(e) => handleFieldChange('required', e.target.checked)} />{' '}

                        <span className={style.labelStyle}>Required Field</span>
                    </div>

                    <div className={style.inputFieldContainer}>
                        <Input
                            className={style.inputField}
                            type="checkbox"
                            checked={hidden}
                            onChange={(e) => handleFieldChange('hidden', e.target.checked)} />{' '}

                        <span className={style.labelStyle}>Hide Field</span>
                    </div>

                    <div className={style.inputFieldContainer}>
                        <Input
                            className={style.inputField}
                            type="checkbox"
                            checked={!show_label}
                            onChange={(e) => handleFieldChange('show_label', !e.target.checked)} />{' '}

                        <span className={style.labelStyle}>Hide Label</span>
                    </div>
                </div>


                <div className={style.columnStyle} style={{ marginTop: '10px' }}>
                    <Label className={style.labelStyle} check
                        hidden={!(name.includes('phoneNumber') && name.includes('value') && getKmnAccount())}
                    >
                        <Input
                            type="checkbox"
                            className={style.inputField}
                            checked={required_opt_in && !hidden}
                            onChange={(e) => handleFieldChange('required_opt_in', e.target.checked)} />{' '}

                        <span className={style.labelStyle}>Show text marketing opt-in</span>
                    </Label>
                </div>

                {urlParamsEnabled && (type === 'text' || type === 'email' || type === 'tel') && <div className={style.columnStyle}>
                    <span className={style.labelStyle}>Field URL parameter</span>
                    <Input
                        className={style.inputField}
                        data-qa="field-url-param"
                        type="text"
                        name="label"
                        value={url_param_text}
                        onChange={(e) => handleFieldChange('url_param_text', e.target.value)} />
                </div>}
            </div>
        );
    }
}

export default TextField;
