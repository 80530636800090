import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { pixelEvents } from '@/unlayer-tools/custom-tools/appointment-tool/calendly/constants/pixel-constants';
import { intl } from '@/shared/intl';
import { UnlayerFormField } from '@/shared/components/UnlayerFormField';
import style from './calendly.scss';
import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';
import circleInfoIcon from '@/icons/info-circle.svg';

export const CalendlyTrackingPropEditor = ({ value = '', updateValue }) => {
    // this will deserialize the value from json into the textarea or the input field
    const getValue = (value, key) => {
        return value[key];
    };

    const [selectValue, setSelectValue] = useState(getValue(value, 'selectValue'));
    const [customValue, setCustomValue] = useState(getValue(value, 'customValue'));
    const [warningMessage, setWarningMessage] = useState(false);
    const [openTrack, setOpenTrack] = useState('');

    const getPixelEventSettingsInformation = async () => {
        try {
            const response = await executeFrameRequest('getSiteSettings');

            if (!response || !isValidTrackingIds(response)) {
                setWarningMessage(true);
            }
            else {
                setTracking(response);
            }
        } catch (error) {
            setWarningMessage(true);
        }
    };

    const setTracking = ({ facebookTrackingId, googleTrackingId }) => {
        if (facebookTrackingId !== '') {
            setOpenTrack('fbq');
        }
        else if (googleTrackingId !== '') {
            setOpenTrack('gtag');
        }
    };

    const isValidTrackingIds = ({ facebookTrackingId, googleTrackingId }) => {
        return (
            (facebookTrackingId && facebookTrackingId !== '') ||
      (googleTrackingId && googleTrackingId !== '')
        );
    };

    //this will serialize a value into the json depending on the input field or the textarea
    useEffect(() => {
        updateValue({ selectValue, customValue, openTrack });

        getPixelEventSettingsInformation();
    }, [openTrack, customValue, selectValue]);

    const handleSelectChange = (e) => {
        setSelectValue(e.target.value);
    };

    const handleTextareaChange = (e) => {
        const value = e.target.value;
        const alphaNumericValue = value.replace(/[^a-z0-9\s-]/gi, ''); // Allow alphanumeric characters, spaces, and dashes

        setCustomValue(alphaNumericValue);
    };

    return (
        <div>
            {warningMessage && (
                <div className={style.labelStyle} style={{ backgroundColor: '#D6F0FF', marginBottom: '5px', padding: '5px', display: 'flex', borderRadius: '8px' }}>
                    <div style={{ margin: '5px' }}><svg height="18" width="18" viewBox={`${circleInfoIcon.viewBox}`}><use href={`#${circleInfoIcon.id}`} /></svg></div>
                    <div>{intl.get('unlayer.tool.warning.error.pixel.events', { defaultMessage: 'Add your Facebook or Google pixel in the settings panel to trigger pixel event' })}</div>
                </div>
            )}
            <UnlayerFormField className={style.labelStyle} label={intl.get('unlayer.tool.booking.calendly.pixel.events.field.select.label', { defaultMessage: 'Event Name' })}>
                <Input
                    type="select"
                    className={style.inputField}
                    onChange={handleSelectChange}
                    id="selectOption"
                    value={selectValue}>
                    <option value="">Select an option</option>
                    {pixelEvents.map((item, index) => (
                        <option key={index} value={item}>
                            {item}
                        </option>
                    ))}
                </Input>
            </UnlayerFormField>
            <hr/>
            {
                selectValue === 'Other Event Type' && (
                    <div>
                        <UnlayerFormField className={style.labelStyle} label={intl.get('unlayer.tool.booking.calendly.pixel.events.field.custom.script', { defaultMessage: 'Other Event Name' })}>
                            <Input
                                className={style.inputField}
                                type="textarea"
                                value={customValue}
                                onChange={handleTextareaChange}
                                rows={5}
                            />
                        </UnlayerFormField>
                        { customValue === '' && <div className={style.labelStyle} style={{ color: 'red', marginTop: '5px' }}>{intl.get('', { defaultMessage: 'Other event name is required' })}</div> }
                    </div>
                )
            }
        </div>
    );
};

export const CalendlyTrackingEditor = {
    name: 'calendly#tracking',
    Widget: CalendlyTrackingPropEditor,
};
