import React from 'react';
import update from 'react-addons-update';

import FieldRow from './fields/FieldRow';
import FieldModal from './fields/FieldModal';
import Select from 'react-select';
import {
    requestCreateFormField,
    requestCreateVirtualField,
} from '@/unlayer-tools/requests/create-form-field-request';
import { UnlayerFormField } from '@/shared/components/UnlayerFormField';
import { intl } from '@/shared/intl';
import { DEFAULT_FORM_FIELDS } from './FormFieldsConstants';
import VirtualFieldWarning from '../../renderer/formFields/VirtualFieldWarning';

const CREATE_NEW_FIELD_VALUE = 'field-options-create-new-field';

export class KeapFieldsEditor extends React.Component {
    constructor(props) {
        super(props);

        this.toggleDefaultFieldModal = this.toggleDefaultFieldModal.bind(this);
        this.toggleHiddenFieldModal = this.toggleHiddenFieldModal.bind(this);
        this.toggleDefaultFieldsDropdown = this.toggleDefaultFieldsDropdown.bind(this);
        this.toggleHiddenFieldsDropdown = this.toggleHiddenFieldsDropdown.bind(this);

        this.state = {
            activeField: null,
            defaultFieldsDropdownOpen: false,
            hiddenFieldsDropdownOpen: false,
            defaultFieldModal: false,
            hiddenFieldModal: false,
            deleteFieldName: null,
            defaultFields: props.data?.defaultFields ?? DEFAULT_FORM_FIELDS,
            virtualFieldSelected: null,
            showVirtualFieldButton: props.data?.showVirtualFieldButton,
            isVisible: true,
            urlParamsEnabled: props.data?.urlParamsEnabled ?? false,
        };
    }

    getValue() {
        const { value = [] } = this.props;

        // ! optimize this!!!
        return {
            formValue: value.filter((item) => item.type !== 'hidden' && item.type !== 'hidden_group').map((item) => {
                if (!item.width) {
                    item.width = 2;
                }

                return item;
            }),
            hiddenValue: value.filter((item) => item.type === 'hidden' || item.type === 'hidden_group'),
        };
    }

    getformFields() {
        const { defaultFields } = this.state;

        if (defaultFields.length) {
            return defaultFields.filter((field) => field.type !== 'hidden' && field.type !== 'hidden_group');
        } else {
            return DEFAULT_FORM_FIELDS;
        }
    }

    getVirtualFieldOptions(options) {
        const labels = options.map((option) => option.label);
        const tags = options.map((option) => option.tags);

        return { labels, tags };
    }

    getHiddenFields() {
        const { defaultFields } = this.state;

        if (defaultFields.length) {
            return defaultFields.filter((field) => field.type === 'hidden' || field.type === 'hidden_group');
        } else {
            return [
                { name: 'hidden_field', label: 'Hidden Field', type: 'hidden' },
            ];
        }
    }

    addDefaultField(field) {
        const {
            type,
            name,
            label,
            group,
            required,
            show_label,
            placeholder_text,
            meta_data,
            deletable,
            date_format,
            date_format_changeable,
        } = field;

        let options = field.options;
        let value;

        let virtualFieldTags;

        if (group === 'virtual_field') {
            const { labels, tags } = this.getVirtualFieldOptions(options);

            virtualFieldTags = tags;
            options = labels.join('\n');
        }

        if (type == 'hidden_group') {
            if (!options) options = [];
            value = typeof options[0] == 'object' ? options[0].value : options[0];
        } else {
            // This is for checkbox and dropdown because their editor UI is a big text field and we ask users to put one option per line
            // TODO: In future, we should let them add label and value for each option so it would change to an array as well
            if (!options) options = '';
            if (typeof options == 'object' && (options.length == 0 || typeof options[0] == 'string')) options = options.join('\n');
        }

        const placeholderText = placeholder_text || intl.get(`tool.form.fields.${name}.placeholder`, { defaultMessage: `Enter your ${label.toLowerCase()}` });

        let newField = {
            type,
            name,
            label,
            options,
            value,
            deletable,
            width: 2,
            placeholder_text: field.defaultToFirstOption ? '' : placeholderText,
            show_label: show_label === true,
            required: required === true,
            required_opt_in: false,
            sms_opt_in: false,
            meta_data: meta_data || {},
            tags: virtualFieldTags || [],
            group: group,
        };

        if (type === 'date') {
            if (date_format) newField['date_format'] = date_format;
            if (typeof date_format_changeable != 'undefined') newField['date_format_changeable'] = date_format_changeable;
        }

        this.addField(newField);
    }

    fieldExists(field, hidden = false) {
        const { formValue, hiddenValue } = this.getValue();
        const values = hidden ? hiddenValue : formValue;

        if (field.group === 'Custom fields') return values.find((value) => value.label === field.label);
        else return values.find((value) => value.name === field.name);
    }

    addField(field) {
        const { updateValue, value } = this.props;
        const { defaultFields } = this.state;
        let newValue = value.slice(0);

        field.width = 2;
        newValue.push(field);
        let newFields = defaultFields.slice(0);

        newFields.push(field);
        this.setState({ defaultFields: newFields });
        updateValue(newValue);
    }

    updateField(fieldName, fieldValue) {
        const { updateValue, value } = this.props;
        let index = 0;
        let updatedValue;

        value.forEach((currentValue, i) => {
            if (currentValue.name === fieldName) {
                index = i;
            }
        });

        updatedValue = update(value, { [index]: { $set: fieldValue } });

        updateValue(updatedValue);

    }

    toggleDefaultFieldsDropdown() {
        this.setState({
            defaultFieldsDropdownOpen: !this.state.defaultFieldsDropdownOpen,
        });
    }

    toggleHiddenFieldsDropdown() {
        this.setState({
            hiddenFieldsDropdownOpen: !this.state.hiddenFieldsDropdownOpen,
        });
    }

    handleEdit(field, hidden = false) {
        if (!field) return;

        if (hidden) {
            this.setState({ hiddenFieldModal: true, activeField: field });
        } else {
            this.setState({ defaultFieldModal: true, activeField: field });
        }
    }

    toggleDefaultFieldModal() {
        this.setState({ defaultFieldModal: !this.state.defaultFieldModal, activeField: null });
    }

    toggleHiddenFieldModal() {
        this.setState({ hiddenFieldModal: !this.state.hiddenFieldModal, activeField: null });
    }

    handleDelete(fieldName) {
        const { updateValue, value } = this.props;
        let newValue = value.slice(0);

        for (const i in newValue) {
            if (newValue[i].name == fieldName) {
                newValue.splice(i, 1);
                break;
            }
        }
        updateValue(newValue);

        this.setState((state) => ({
            ...state,
            defaultFieldModal: false,
            fieldModal: false,
            activeField: null,
        }));
    }

    moveField(dragIndex, hoverIndex, hidden) {
        const { updateValue } = this.props;
        const { formValue, hiddenValue } = this.getValue();
        let value = hidden ? hiddenValue : formValue;
        let otherValue = hidden ? formValue : hiddenValue;

        let newValue = value.slice(0);
        let temp = newValue[dragIndex];

        newValue[dragIndex] = newValue[hoverIndex];
        newValue[hoverIndex] = temp;
        updateValue([...newValue, ...otherValue]);
    }

    // This tells the Keap-web & Max-classic to send the request for creating the virtual field,
    // then add the field to the form.

    handleVirtualFieldSelection = async () => {
        try {
            const field = await requestCreateVirtualField();

            this.addDefaultField(field);
        } catch (e) {
            console.warn('Creating a virtual field failed.');
        }

    }

    /**
   * Tells the parent frame to launch the UI for creating a new custom field.  If a new field is created,
   * then add the field to the form, and update state
   */
    async createCustomField() {
        try {
            const field = await requestCreateFormField();

            if (!field) {
                console.warn('UNLAYER: Cancelled custom field creation');
            } else {
                this.addDefaultField(field);
                this.setState({
                    inputValue: '',
                    addFieldValue: null,
                });
            }
        } catch (e) {
            console.warn('UNLAYER: Error attempting to create custom field', e);
        }
    }

    handleAlertClose() {
        this.setState({ isVisible: false });
    }

    render() {

        const { formValue } = this.getValue();
        const { activeField, defaultFieldModal, showVirtualFieldButton } = this.state;

        const usedFormFieldNames = formValue.map((field) => activeField && field.name == activeField.name ? null : field.name);
        const fieldCount = formValue.length;
        const formFields = this.getformFields();
        const { values: { formLayoutType = 'dynamic' } = {} } = this.props;

        const optionsFlat = formFields.reduce((prev, field, index) => {
            if (!this.fieldExists(field, true) && !field.name?.toLowerCase()?.endsWith('smsoptin')) {
                prev.push({
                    name: field.name,
                    value: `default-field-${index}`,
                    label: field.label,
                    field: field,
                });
            }

            return prev;
        }, []);

        const optionsKeyed = {};
        const options = [];

        optionsFlat.forEach((option) => {
            const { field } = option;
            const group = field.group ?? 'General';

            if (!optionsKeyed[group]) {
                const groupLiteral = {
                    label: group,
                    options: [],
                };

                options.push(groupLiteral);
                optionsKeyed[group] = groupLiteral;
            }
            option.isDisabled = this.fieldExists(field);
            optionsKeyed[group].options.push(option);
        });

        // Add the option to create a new field at the bottom
        options.push({
            label: 'Create custom field',
            value: CREATE_NEW_FIELD_VALUE,
        });

        const onFieldSelected = ({ value, field } = {}) => {
            if (value === CREATE_NEW_FIELD_VALUE) {
                this.createCustomField().then(() => { });
            } else {
                this.addDefaultField(field);
                this.setState({
                    inputValue: '',
                    addFieldValue: null,
                });
            }
        };



        return (
            <>
                <UnlayerFormField label={this.props.label}>
                    {formValue.map((field, index) => {
                        return (
                            <FieldRow
                                key={`field-${field.name}`}
                                index={index}
                                hidden={false}
                                field={field}
                                formLayoutType={formLayoutType}
                                updateField={this.updateField.bind(this)}
                                onClick={() => this.handleEdit(field)}
                                moveField={this.moveField.bind(this)} />
                        );
                    })}

                    <Select options={options}
                        blurInputOnSelect={true}
                        placeholder={intl.get('tool.form.field.placeholder', { defaultMessage: 'Add a field' })}
                        value={this.state.addFieldValue}
                        onChange={onFieldSelected}
                        inputValue={this.state.inputValue}
                        onInputChange={(inputValue) => this.setState({ inputValue })}
                        menuIsOpen={this.state.menuIsOpen}
                        onMenuOpen={() => this.setState({ menuIsOpen: true })}
                        onMenuClose={() => this.setState({ menuIsOpen: false })} />


                    {showVirtualFieldButton && (
                        <>
                            <hr />
                            <button
                                className='virtual-field-button'
                                onClick={this.handleVirtualFieldSelection}>
                                {intl.get('unlayer.tool.virtual.field.placeholder', { defaultMessage: 'Add a Virtual field' })}
                            </button>

                            <VirtualFieldWarning
                                alertMessage={intl.get('unalyer.tool.form.virtual.field.alert.message',
                                    { defaultMessage: 'Virtual fields are an alternative to custom fields if you are looking to trigger automation from a tag on a field. These fields will not sync to a contact record.' })}
                            />
                        </>
                    )}

                    <FieldModal
                        fields={formFields}
                        hidden={false}
                        usedFieldNames={usedFormFieldNames}
                        fieldCount={fieldCount}
                        field={activeField}
                        isOpen={defaultFieldModal}
                        addField={this.addField.bind(this)}
                        updateField={this.updateField.bind(this)}
                        deleteField={this.handleDelete.bind(this)}
                        toggle={this.toggleDefaultFieldModal}
                        urlParamsEnabled={this.state.urlParamsEnabled} />
                </UnlayerFormField>
            </>
        );
    }
}
