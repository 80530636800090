import React from 'react';
import update from 'react-addons-update';
import { findDOMNode } from 'react-dom';
import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { Card, CardHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from '../../styles/FormEditorStyle.scss';


class FieldRow extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            tooltipOpen: false,
        };

        this.handleLayoutChange = (value) => {
            const { field, updateField } = this.props;

            const newField = update(field, {
                width: { $set: value },
            });

            updateField(field.name, newField);
        };

        this.toggle = () => {
            this.setState({ tooltipOpen: !this.state.tooltipOpen });
        };
    }

    render() {
        const { field, onClick } = this.props;
        const { isDragging, connectDragSource, connectDragPreview, connectDropTarget } = this.props;
        const styles = {
            border: isDragging ? '2px dashed #CCC' : '',
        };

        return connectDropTarget(
            <div className={styled.fieldRowContainer}>
                <Card style={styles}>
                    {connectDragPreview(
                        <div>
                            {connectDragSource(
                                <div>
                                    <CardHeader style={{ opacity: isDragging ? 0 : 1, cursor: 'default', maxHeight: '100%' }}
                                        role="button">
                                        <div className={styled.draggableCardContainer}>
                                            <div className="col-1">
                                                <i><FontAwesomeIcon icon={faSort} /></i>
                                            </div>
                                            <div className={'col-9'}>{field.label}</div>
                                            <div className="col-2 text-right">
                                                <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faPencilAlt}
                                                    data-automation-id="edit-button" onClick={onClick} />
                                            </div>
                                        </div>
                                    </CardHeader>
                                </div>,
                            )}
                        </div>,
                    )}
                </Card>
            </div>,
        );
    }
}

const fieldSource = {
    beginDrag(props) {
        return {
            id: props.id,
            index: props.index,
            srcHidden: props.hidden,
        };
    },
};

const fieldTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        const srcHidden = monitor.getItem().srcHidden;

        // Don't change order of the other field group
        if (srcHidden !== component.props.hidden) {
            return;
        }

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        // eslint-disable-next-line react/no-find-dom-node
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveField(dragIndex, hoverIndex, props.hidden);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

// eslint-disable-next-line no-class-assign
FieldRow = DragSource(
    'FIELD',
    fieldSource,
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }),
)(FieldRow);

// eslint-disable-next-line no-class-assign
FieldRow = DropTarget(
    'FIELD',
    fieldTarget,
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    }),
)(FieldRow);

// eslint-disable-next-line react/display-name
export default (props) => (
    <DndProvider backend={HTML5Backend} context={global}>
        <FieldRow {...props} />
    </DndProvider>
);
