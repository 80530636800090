import { intl } from '@/shared/intl';

export const CalendlyOptions = ({ funnelsCalendlyEmbed, tags }) => {
    const CalendlyOptionsConstants = {
        useCalendlyLink: {
            label: intl.get('unlayer.tool.booking.calendly.prop.use', { defaultMessage: 'Calendly Appointments' }),
            defaultValue: false,
            widget: 'toggle',
        },
        calendlyLink: {
            label: intl.get('unlayer.tool.booking.calendly.prop.link', { defaultMessage: 'Calendly link' }),
            defaultValue: '',
            widget: 'calendly',
        },
        nameUrlParam: {
            label: intl.get('unlayer.tool.booking.calendly.prop.url.pram.name', { defaultMessage: 'Url Parameter For Name' }),
            defaultValue: '',
            widget: 'text',
        },
        emailUrlParam: {
            label: intl.get('unlayer.tool.booking.calendly.prop.url.pram.email', { defaultMessage: 'Url Parameter For Email' }),
            defaultValue: '',
            widget: 'text',
        },
        tagApply: {
            defaultValue:  [],
            data: {
                field: {
                    label: intl.get('tool.properties.tagApply', { defaultMessage: 'Apply Tag' }),
                    show_label: true,
                    required: false,
                    className: 'field-container field-container-dynamic',
                    placeholder: intl.get('tool.properties.tagSelectPlaceholder', { defaultMessage: 'Select options' }),
                    options: tags,
                    isCreatable: true,
                },
            },
            widget: 'multiselectTags',
        },
        pixelEvent: {
            label: intl.get('unlayer.tool.booking.calendly.prop.tracking', { defaultMessage: 'Tracking' }),
            defaultValue: {
                selectValue: '',
                customValue: '',
                openTrack: '',
            },
            widget: 'calendly#tracking',
        },
    };

    return funnelsCalendlyEmbed ? CalendlyOptionsConstants : {};
};


